@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Changa:wght@300;400;500;700;800&display=swap');
body,
html {
  font-family: "Poppins",'Changa', sans-serif !important;
}
.noLink{
  all: unset !important;
  cursor: pointer !important;
}
.loader_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background: white;
}
.err{
  color: #FF0010;
  font-size: 12px;
  margin-top: 5px;
}
.error-inputs{
  border: 0.5px solid #FC0D22 !important;
}
:root {
  --font-base: "Poppins", sans-serif;
  --primary-color: #edf2f8;
  --secondary-color: #313bac;
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --gray-color: #6b7688;
  --brown-color: #46364a;
  --white-color: #ffffff;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
.form-control:focus{
  box-shadow : none !important;
}
.inputFile {
  opacity: 0;
  position: absolute;
  cursor: pointer !important;
}
.cursorPointer{
  cursor: pointer !important;
}
.marginTop{
  margin-top: 130px !important;
}
