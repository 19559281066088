/*--------------------------------- navBar -----------------------------*/
.navbar {
    padding: 0px 5% !important;
    background-color: transparent !important;
    min-height: 80px !important;
}

.navbar.changebg {
    background-color: white !important;
    min-height: 80px !important;
    box-shadow: 0px 3px 15px #00000012;
}

.navbar-nav .nav-link.active{
    color: white !important;
    background-color: orange;
    font-weight: 900 !important;
}

.nav-link {
    font-size: 16px;
    position: relative;
    color: #DBB166 !important;
    font-weight: 400 !important;
    border-radius: 36px;
}

.btn-close {
    width: 2em !important;
    height: 2em !important;
    margin-top: 0.5rem;
    padding: 5px !important;
    background-size: cover;
}

@media (max-width: 990px) {
    .nav-item {
        position: relative;
    }
    .current:after {
        top: 10px;
        right: 30%;
        position: absolute;
    }
}

.navbar .logo {
    width: 130px;
    height: 30px;
    margin: auto;
}

.navbar-nav{
    align-items: center;
}

@media (max-width: 1200px) {
    .navbar {
        padding: 0px 1% !important;
    }
}

@media (max-width: 992px) {
    .offcanvas {
        background: #fff;
        border-radius: 0px 11px 11px 0px;
        opacity: 0.95;
        backdrop-filter: blur(46px);
        -webkit-backdrop-filter: blur(46px);
    }

    .changebg .offcanvas {
        background: #fff;
        border-radius: 0px 11px 11px 0px;
        opacity: 0.9;
        backdrop-filter: blur(46px);
        -webkit-backdrop-filter: blur(46px);
    }

    .offcanvas-end {
        top: 0;
        width: 300px;
        border-left: none;
        transform: translateX(100%);
    }

    .offcanvas.show {
        left: 0;
    }
    .navbar-nav{
        align-items: start;
    }

}

@media (min-width: 992px) {

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-left: 0.8rem !important;
        padding-right: 0.8rem !important;
        font-size: 13px;
        font-weight: 600 !important;
    }
}

.navbar-toggler {
    border: none !important;
}

.navbar-toggler:focus {
    box-shadow: none !important;
}

@media screen and (max-width: 1280px) and (min-width: 900px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-left: 0.3rem !important;
        padding-right: 0.3rem !important;
        font-size: 13px;
        font-weight: 600 !important;
    }
}

.nav-image-title img{
    width:30px;
    height:30px;
    border-radius:50px;
    margin-right: 10px;
}

.nav-left-title{
    display: flex;
    align-items: center;

}

.nav-name-title p {
    margin-bottom: 0;
    font-size: 14px;
}

.edit-create-btn{
    border-color:#00979E !important;
    font-size:13px !important;
    color:#00979E !important;
    padding: 7px 36px !important;
}

.edit-create-btn:hover
{
    background-color: #00979E !important;
    color: white !important;
}

.edit-join-btn{
    background-color:#DBB166 !important;
    font-size:13px !important;
    color:white !important;
    padding: 8px 50px !important;
    height: 80px;
}
.edit-join-btn svg {
    font-size: 15px;
    margin-left:10px;
}
.header-icons{
    display: flex;
    justify-content: space-between;
    padding-inline: 5%;
}
.title-header-here img
{
    width: 15px;
    margin-right: 11px;
}
.title-header-here {
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 1%;
}
.icon-social-header {
    display: flex;
    flex-wrap: wrap;
    gap:20px;
    padding: 1%;
}
@media (max-width: 767px) {
    .header-icons{
        flex-wrap: wrap;
        padding-inline: 1%;
    }
    .title-header-here {
        font-size: 10px;
        font-weight: bold;
        flex-direction: column;
        align-items: unset;
        gap: 8px;
    }
    .icon-social-header{
        margin: auto;padding-top: 10px;
    }
    .edit-join-btn {
        background-color: #DBB166 !important;
        font-size: 13px !important;
        color: white !important;
        padding: 0 30px !important;
        height: 45px;
    }
}
@media (max-width: 990px) {
    .edit-join-btn {
        background-color: #DBB166 !important;
        font-size: 13px !important;
        color: white !important;
        padding: 0 30px !important;
        height: 45px;
    }
}


